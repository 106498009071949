import styled from "styled-components";

const Address = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 20px;

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 16px;
  }
`;

export default Address;
