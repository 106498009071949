import styled from "styled-components";

const ItemHeader = styled.div`
  font-family: "Montharo";
  text-transform: uppercase;
  color: white;
  font-size: 24px;
  letter-spacing: 2px;

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 18px;
  }
`;

export default ItemHeader;
