import React from "react";
import styled from "styled-components";

const Download = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 2rem;
  background-color: #ea520e;
`;

const DownloadText = styled.div`
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  letter-spacing: 2px;
  line-height: 22px;
  margin-bottom: 0.5rem;

  @media (min-width: 901px) {
    font-size: 28px;
    letter-spacing: 2px;
    line-height: 30px;
  }
`;

const DownloadIcon = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    path {
      fill: rgba(255, 255, 255, 1);
    }
  }
`;

const DownloadMenu = () => (
  <section>
    <Download>
      <DownloadIcon
        href="documents/Beach_meny.pdf"
        target="_blank"
        rel="noopener"
        aria-label="Ladda ner meny"
      >
        <DownloadText>Ladda ner vår meny</DownloadText>
        <svg
          width="24"
          height="24"
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M457.7,230.15c-7.5,0-13.5,6-13.5,13.5v122.8c0,33.4-27.2,60.5-60.5,60.5H87.5c-33.4,0-60.5-27.2-60.5-60.5v-124.8
			c0-7.5-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v124.8c0,48.3,39.3,87.5,87.5,87.5h296.2c48.3,0,87.5-39.3,87.5-87.5v-122.8
			C471.2,236.25,465.2,230.15,457.7,230.15z"
          />
          <path
            d="M226.1,346.75c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l85.8-85.8c5.3-5.3,5.3-13.8,0-19.1c-5.3-5.3-13.8-5.3-19.1,0l-62.7,62.8
			V30.75c0-7.5-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v273.9l-62.8-62.8c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1
			L226.1,346.75z"
          />
        </svg>
      </DownloadIcon>
    </Download>
  </section>
);

export default DownloadMenu;
