import styled from "styled-components";

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${prop => (prop.fill ? `height: 100%;` : null)};
`;

export default Overlay;
