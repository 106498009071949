import styled from "styled-components";

const Col = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;

  @media (min-width: 601px) and (max-width: 900px) {
    margin: 0.5rem;
  }
`;

export default Col;
