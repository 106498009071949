import React from "react";

import {
  Landing,
  /*
  Takeaway,
  DownloadMenu,
  Story,
  Contact,
  */
  Footer,
} from "../../components";

const Home = () => (
  <main>
    <Landing />
    {/*
    <Takeaway />
    <Story />
    <DownloadMenu />
    <Contact />
    */}
    <Footer />
  </main>
);

export default Home;
