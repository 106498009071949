import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { GlobalStyle, theme } from "./theme";
import { ScrollToTop } from "./components";
import { AppContainer } from "./styles";

import { Home, FourOFour } from "./pages";

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ScrollToTop>
            <AppContainer>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route component={FourOFour} />
              </Switch>
            </AppContainer>
          </ScrollToTop>
          <GlobalStyle />
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default App;
