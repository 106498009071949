import styled from "styled-components";

const Open = styled.div`
  font-size: 32px;
  text-transform: uppercase;
  text-decoration: none;
  color: ${props => props.theme.white};
`;

export default Open;
