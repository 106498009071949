import styled from "styled-components";

const TextLarge = styled.div`
  font-size: 20px;
  letter-spacing: 2px;

  text-transform: uppercase;
  color: ${prop => (prop.dark ? "rgba(0, 0, 0, 0.6)" : "#fff")};
  text-align: ${prop => (prop.center ? "center" : "left")};
  width: 70%;
  line-height: 24px;

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 28px;
    line-height: 28px;
    letter-spacing: 4px;
  }

  @media (min-width: 901px) {
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 4px;
  }
`;

export default TextLarge;
