import styled from "styled-components";

const Version = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 12px;
  font-size: 12px;
  color: ${props => props.theme.white};
`;

export default Version;
