import styled from "styled-components";

const Item = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 580px;
  margin-bottom: 2rem;

  @media (min-width: 601px) and (max-width: 900px) {
    margin-bottom: 1.3rem;
  }
`;

export default Item;
