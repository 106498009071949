import styled from "styled-components";

const Link = styled.a`
  font-size: 32px;
  text-transform: uppercase;
  text-decoration: none;
  color: ${props => props.theme.white};
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background: #fff;
    height: 1px;
  }
`;

export default Link;
