import styled from "styled-components";

const BackgroundImage = styled.div`
	background-image: url('images/backgrounds/${prop => prop.src}-lg_1x.jpg');

    background-image: -webkit-image-set(
        url('images/backgrounds/${prop => prop.src}-lg_1x.webp') 1x,
        url('images/backgrounds/${prop => prop.src}-lg_2x.webp') 2x
    );
    background-image: image-set(
        url('images/backgrounds/${prop => prop.src}-lg_1x.jpg') 1x,
        url('images/backgrounds/${prop => prop.src}-lg_2x.jpg') 2x
    );

    @media (max-width: 900px) {
        background-image: url('images/backgrounds/${prop => prop.src}-md_2x.jpg');
        background-image: -webkit-image-set(
            url('images/backgrounds/${prop => prop.src}-md_1x.webp') 1x,
            url('images/backgrounds/${prop => prop.src}-md_2x.webp') 2x
        );
        background-image: image-set(
            url('images/backgrounds/${prop => prop.src}-md_1x.jpg') 1x,
            url('images/backgrounds/${prop => prop.src}-md_2x.jpg') 2x
        );
    }

    @media (max-width: 600px) {
        background-image: url('images/backgrounds/${prop => prop.src}-sm_2x.jpg');
        background-image: -webkit-image-set(
            url('images/backgrounds/${prop => prop.src}-sm_1x.webp') 1x,
            url('images/backgrounds/${prop => prop.src}-sm_2x.webp') 2x
        );
        background-image: image-set(
            url('images/backgrounds/${prop => prop.src}-sm_1x.jpg') 1x,
            url('images/backgrounds/${prop => prop.src}-sm_2x.jpg') 2x
        );
    }

	background-size: cover;
	background-position: center center;
	height: ${prop => (prop.height ? `${prop.height}px` : "600px")};
	display: flex;
    flex-direction: column;
	justify-content: center;
	align-items: center;

	@media (min-width: 601px) and (max-width: 900px) {
		height: ${prop => (prop.height ? `${prop.height}px` : "800px")};
	}

	@media (min-width: 901px) {
		height: ${prop => (prop.height ? `${prop.height}px` : "1100px")};
		width: 100%;
        flex-direction: row;
	}
`;

export default BackgroundImage;
