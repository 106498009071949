import styled from "styled-components";

const SocialIcon = styled.a`
  padding: 15px;
  svg {
    path {
      fill: rgba(255, 255, 255, 1);
    }
  }
`;

export default SocialIcon;
