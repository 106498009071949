import styled from "styled-components";

const SubHeader = styled.div`
  font-size: 14px;
  color: ${prop => (prop.dark ? "rgba(0, 0, 0, 0.6)" : "#fff")};
  text-align: ${prop => (prop.center ? "center" : "left")};
  width: 70%;
  line-height: 24px;

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 21px;
    line-height: 35px;
  }

  @media (min-width: 901px) {
    font-size: 18px;
    line-height: 30px;
    max-width: 700px;
  }
`;

export default SubHeader;
