import styled from "styled-components";

const ItemContent = styled.div`
  display: flex;
  color: white;

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 14px;
  }
`;

export default ItemContent;
