import React from "react";

import { Logo } from "../../components";

import {
  BackgroundImage,
  Overlay,
  Header,
  SubHeader,
  Spacer,
} from "../../styles";

import { Mobile, Tablet, Desktop } from "../../theme";

const data = {
  header: "Vi har valt att stänga Beach",
  subheader:
    "Vi vill tacka alla kunder för denna gång! Vi hoppas kunna erbjuda Beach goda burgare någon annanstans i framtiden. Tack!",
};

const Landing = () => (
  <section>
    <Mobile>
      <BackgroundImage src="landing">
        <Overlay>
          <Logo />
          <Spacer height="40" />
          <Header>{data.header}</Header>
          <Spacer height="24" />
          <SubHeader center>{data.subheader}</SubHeader>
        </Overlay>
      </BackgroundImage>
    </Mobile>
    <Tablet>
      <BackgroundImage src="landing">
        <Overlay>
          <Logo />
          <Spacer height="104" />
          <Header>{data.header}</Header>
          <Spacer height="34" />
          <SubHeader center>{data.subheader}</SubHeader>
          {/*<SubHeader center>
            Och tänk nu finns det ett sådant ställe i Alingsås.
          </SubHeader>
          <SubHeader center>
            Välkommen till Beach på Gerdska strand, här serverar vi riktiga
            burgare, tjocka shakes och annat gott! Allt med ett stänk av
            kalifornisk surfkultur.
          </SubHeader>*/}
          <Spacer height="24" />
        </Overlay>
      </BackgroundImage>
    </Tablet>
    <Desktop>
      <BackgroundImage src="landing">
        <img
          src="./images/logo.png"
          alt="Beach Alingsås"
          width="240"
          height="124"
          style={{ position: "absolute", top: "75px" }}
        />
        <Overlay>
          <Spacer height="24" />
          <Header>{data.header}</Header>
          <Spacer height="40" />
          <SubHeader center>{data.subheader}</SubHeader>
          {/*<SubHeader center>
            Välkommen till Beach på Gerdska strand, här serverar vi riktiga
            burgare, tjocka shakes och annat gott!
          </SubHeader>
          <SubHeader center>
            Allt med ett stänk av kalifornisk surfkultur.
        </SubHeader>*/}
        </Overlay>
      </BackgroundImage>
    </Desktop>
  </section>
);

export default Landing;
