import React from "react";
import { Link } from "react-router-dom";

const FourOFour = () => (
  <div>
    404 - Sidan hittades inte. Gå till <Link to="/">start</Link>.
  </div>
);

export default FourOFour;
