import styled from "styled-components";

const FooterContainer = styled.div`
  background-color: ${(props) => props.theme.green};
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 601px) {
    //height: 390px;
  }

  @media (min-width: 901px) {
    //height: 460px;
  }
`;

export default FooterContainer;
