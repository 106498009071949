import styled from "styled-components";

const LogoImage = styled.img`
  height: ${props => (props.transparant ? "107px" : "73px")};
  width: ${props => (props.transparant ? "213px" : "140px")};

  @media (min-width: 601px) and (max-width: 900px) {

  }

  @media (min-width: 901px) {
    height: ${props => (props.transparant ? "107px" : "151px")};
    width: ${props => (props.transparant ? "213px" : "298px")};
  }
`;

export default LogoImage;
