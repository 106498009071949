import styled from "styled-components";

const Button = styled.a`
  color: white;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  border: 2px solid white;
  padding: 0.5rem 2rem;
  letter-spacing: 2px;
`;

export default Button;
