import styled, { createGlobalStyle } from "styled-components";

export const theme = {
  white: "#FFFFFF",
  black: "#000000",
  darkgrey: "#4D4D4D",
  green: "#008299"
};

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Montharo";
    src: url("./fonts/montharo-regular.ttf");
  }

  @font-face {
    font-family: "Adelle";
    src: url("./fonts/adelle-sans-regular.woff");
  }

  html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Adelle", sans-serif;
  }

  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    
  }

  @media (max-width: 600px) {
    body {
      -webkit-user-select: none;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
    }
  }
`;

export const Mobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export const Tablet = styled.div`
  display: none;

  @media (min-width: 601px) and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export const Desktop = styled.div`
  display: none;

  @media (min-width: 901px) {
    display: flex;
    flex-direction: ${prop => (prop.column ? "column" : "row")};
    flex: 1;
  }
`;
