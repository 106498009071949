import styled from "styled-components";

const TextSmall = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 17px;
  margin-top: 2rem;

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 14px;
  }
`;

export default TextSmall;
