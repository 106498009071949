import React from "react";
import { LogoImage } from "../../styles";

const Logo = props => (
  <LogoImage
    src={
      props.transparant ? "./images/logo_transparant.png" : "./images/logo.png"
    }
    alt="Beach Alingsås"
    transparant={props.transparant}
  />
);

export default Logo;
