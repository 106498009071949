import styled from "styled-components";

const Header = styled.div`
  font-family: "Montharo";
  font-size: 34px;
  color: ${prop => (prop.dark ? "rgba(0, 0, 0, 0.6)" : "#fff")};
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 8px;
  width: 68%;

  @media (min-width: 601px) {
    &::before,
    ::after {
      content: " ";
      position: absolute;
      left: 50%;
      width: 60%;
      border: 0.5px solid rgba(255, 255, 255, 0.5);
      transform: translateX(-50%);
    }

    &::before {
      margin-top: -20px;
    }

    &::after {
      margin-top: 60px;
    }
  }

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 65px;
    letter-spacing: 16px;

    &::after {
      margin-top: 80px;
    }
  }

  @media (min-width: 901px) {
    font-size: 65px;
    letter-spacing: 16px;

    &::before {
      margin-top: -18px;
    }

    &::after {
      margin-top: 72px;
    }
  }
`;

export default Header;
