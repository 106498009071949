export const data = {
  name: "Restaurang Beach",
  street: "Gerdska strand 15",
  zip: "441 39",
  city: "Alingsås",
  phone: "0322-784 40",
  email: "info@beachalingsas.se",
  googlemaps: "https://goo.gl/maps/PqiTm88seP62",
  socialmedia: [
    {
      name: "Instagram",
      url: "https://www.instagram.com/beachalingsas/",
    },
    {
      name: "facebook",
      url: "https://www.facebook.com/beachalingsas/",
    },
  ],
  openhours: ["Fre-Lör 11-21"],
};
