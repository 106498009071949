import styled from "styled-components";

const Text = styled.div`
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  letter-spacing: 2px;
  line-height: 22px;

  @media (min-width: 901px) {
    font-size: 28px;
    letter-spacing: 2px;
    line-height: 30px;
  }
`;
export default Text;
